<template>
  <div class="confirmDelete">
    <b-modal id="confirmDeleteModal" :title="'Delete Event'" hide-footer>
      <p>Are you sure you want to delete?</p>
      <div class="flex flex-column text-center">
        <button class="btn btn-danger w-25 mr-4" @click="$emit('confirm')">Yes</button>
        <button class="btn btn-light w-25" @click="dismissModal">No</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  methods: {
    dismissModal() {
      this.$bvModal.hide('confirmDeleteModal')
    }
  }
}
</script>
