<template>
  <b-container>
    <b-row class="mb-4">
      <b-col class="mb-2 mb-md-0 d-flex justify-content-start" cols="12" md="6">
        <h1 class="font-weight-bold mb-0 text-primary">Calendar</h1>
      </b-col>
      <b-col class="mb-2 mb-md-0 d-flex justify-content-end" cols="12" md="6">
        <b-button @click="openNewEventModal()">Add Event</b-button>
      </b-col>
    </b-row>

    <b-row class="calendar-wrapper">
      <b-col cols="12">
        <FullCalendar v-if="!isLoading" :options="calendarOptions"/>
        <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-modal id="calendarModal" :title="'View Event'" hide-footer width="2/5" width-lg="3/5">
          <b-form @submit.prevent="submit">
            <b-form-group label="Title">
              <b-form-input v-model="newEventTitle" readonly size="sm"/>
            </b-form-group>

            <b-form-group label="Text">
              <b-form-textarea v-model="newEventText" readonly rows="6" size="sm"/>
            </b-form-group>

            <b-form-group label="Fitter">
              <b-form-select v-model="newEventColor" disabled readonly size="sm">
                <b-form-select-option :value="null" default>No Fitter Selected</b-form-select-option>
                <b-form-select-option value="#4cd137">Darren</b-form-select-option>
                <b-form-select-option value="#00a8ff">Scott</b-form-select-option>
                <b-form-select-option value="#8c7ae6">Jonathan</b-form-select-option>
                <b-form-select-option value="#d63031">Mark</b-form-select-option>
                <b-form-select-option value="#fbc531">Bobby</b-form-select-option>
                <b-form-select-option value="#e67e22">Craig</b-form-select-option>
                <b-form-select-option value="#e84393">Danny</b-form-select-option>
                <b-form-select-option value="#b2bec3">Dave</b-form-select-option>
                <b-form-select-option value="#e1b12c">Mick</b-form-select-option>
                <b-form-select-option value="#636e72">Carl</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group label="Fitting Start Date">
                  <b-form-datepicker v-model="newEventStartDate" readonly size="sm"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group label="Fitting Start Time">
                  <b-form-input v-model="newEventStartTime" readonly size="sm"/>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group label="Fitting End Date">
                  <b-form-datepicker v-model="newEventEndDate" readonly size="sm"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group label="Fitting End Time">
                  <b-form-input v-model="newEventEndTime" readonly size="sm"/>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group label="All Day Fitting">
                  <b-form-checkbox v-model="newEventAllDayFitting" disabled readonly size="sm"></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="col-span-2">
              <div class="flex flex-row ml-auto space-x-2 w-full lg:w-96">
                <b-button block variant="light" @click="hideModal()">Close</b-button>
                <router-link v-if="this.eventFittingId" v-b-popover.bottom="'View this fitting'"
                             :to="{  name: 'dash.fittings.view',  params: { id: eventFittingId } }"
                             class="btn btn-primary d-block">View Fitting
                </router-link>
                <b-button block variant="danger" @click="showDeleteModal()">Delete</b-button>
              </div>
            </div>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>

    <!-- Add None Order Event-->
    <b-modal id="addEventModal" :title="'Add Event'" hide-footer width="2/5" width-lg="3/5">
      <b-form @submit.prevent="submit">
        <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'title')" label="Title">
          <b-form-input v-model="newEventTitle" :disabled="isSaving" :state="validationState(errors.errors,'title')"/>
        </b-form-group>

        <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'text')" label="Text">
          <b-form-textarea v-model="newEventText" :disabled="isSaving" :state="validationState(errors.errors,'text')"/>
        </b-form-group>

        <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'color')" label="Fitter">
          <b-form-select v-model="newEventColor" :disabled="isSaving" :state="validationState(errors.errors,'color')">
            <b-form-select-option :value="null" default>No Fitter Selected</b-form-select-option>
            <b-form-select-option value="#4cd137">Darren</b-form-select-option>
            <b-form-select-option value="#00a8ff">Scott</b-form-select-option>
            <b-form-select-option value="#8c7ae6">Jonathan</b-form-select-option>
            <b-form-select-option value="#d63031">Mark</b-form-select-option>
            <b-form-select-option value="#fbc531">Bobby</b-form-select-option>
            <b-form-select-option value="#e67e22">Craig</b-form-select-option>
            <b-form-select-option value="#e84393">Danny</b-form-select-option>
            <b-form-select-option value="#b2bec3">Dave</b-form-select-option>
            <b-form-select-option value="#e1b12c">Mick</b-form-select-option>
            <b-form-select-option value="#636e72">Carl</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-row>
          <b-col cols="12">
            <b-form-group
              :invalid-feedback="validationInvalidFeedback(errors.errors, 'all_day')" label="All Day Fitting">
              <b-input-group>
                <b-form-checkbox v-model="newEventAllDayFitting" :disabled="isSaving"
                                 :state="validationState(errors.errors, 'all_day')"
                                 @click="disableEventTime"></b-form-checkbox>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'start_date')"
                          label="Fitting Start Date">
              <b-input-group>
                <b-form-datepicker v-model="newEventStartDate"
                                   :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                   :disabled="isSaving"
                                   :state="validationState(errors.errors,'start_date')"
                                   close-button
                                   reset-button
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'start_time')"
                          label="Fitting Start Time">
              <b-input-group>
                <b-form-input v-model="newEventStartTime" :disabled="isSaving || newEventAllDayFitting"
                              :state="validationState(errors.errors,'start_time')"
                              :value="newEventStartTime"
                              placeholder="HH:MM"/>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'end_date')"
                          label="Fitting End Date">
              <b-input-group>
                <b-form-datepicker v-model="newEventEndDate"
                                   :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                   :disabled="isSaving"
                                   :state="validationState(errors.errors,'end_date')"
                                   close-button
                                   reset-button
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group
              :invalid-feedback="validationInvalidFeedback(errors.errors,'end_time')"
              label="Fitting End Time">
              <b-input-group>
                <b-form-input v-model="newEventEndTime" :disabled="isSaving || newEventAllDayFitting"
                              :state="validationState(errors.errors,'end_time')" placeholder="HH:MM"/>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="col-span-2">
          <div class="flex flex-row ml-auto space-x-2 w-full lg:w-96">
            <b-button block type="submit" variant="light">
              <b-spinner v-if="isSaving" small></b-spinner>
              <span v-if="!isSaving">Save</span>
            </b-button>
            <b-button block variant="dark" @click="closeNewEventModal()">Close</b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <confirm-delete @confirm="confirmDelete"></confirm-delete>
  </b-container>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

import validation from "../../mixins/validation";
import momentMixin from "../../mixins/momentMixin";
import currentUser from "../../mixins/currentUser";

// Calendar.
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";

// Components
import ConfirmDelete from "@/components/ConfirmDelete";

export default {
  mixins: [validation, momentMixin, currentUser],
  components: {
    FullCalendar,
    ConfirmDelete
  },

  created() {
    this.fetchMany();
  },

  mounted() {
    if (this.$route.query.customer_id) {
      this.event.customer_id = this.$route.query.customer_id;
    }
  },

  computed: {
    ...mapGetters("calendars", ["data", "errors", "isDeleting", "isLoading", "isSaving", "pagination", "single"]),

  },

  data() {
    return {
      eventId: null,
      eventOrderId: null,
      newEventTitle: null,
      newEventText: null,
      newEventStartDate: null,
      newEventEndDate: null,
      newEventStartTime: null,
      newEventEndTime: null,
      newEventAllDayFitting: null,
      newEventColor: null,
      eventQuantity: null,
      eventMeasurementType: null,
      eventPostcode: null,
      eventFittingId: null,
      eventStaircase: null,
      eventColor: null,
      customer_id: null,
      customer: {},
      order_id: null,
      order: {},
      showDelete: false,


      calendarOptions: {
        headerToolbar: {
          center: "dayGridDay,timeGridWeek", // buttons for switching between views
        },
        views: {
          dayGridDay: {
            type: "dayGridDay",
            duration: {days: 1},
            buttonText: "Day"
          },
          timeGridWeek: {
            type: "timeGridWeek",
            duration: {days: 7},
            buttonText: "Week"
          }
        },
        plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin],
        initialView: "timeGridWeek",
        themeSystem: "bootstrap",
        editable: false,
        selectable: false,
        displayEventTime: false,
        events: "/api/calendars",
        eventClick: info => {
          this.eventFittingId = info.event._def.extendedProps.event.order_fitting ? info.event._def.extendedProps.event.order_fitting.order_id : null;
          if (info.event._def.extendedProps.fitting_id) {
            this.$router.push({name: 'dash.fittings.view', params: {id: this.eventFittingId}})
          } else {
            this.eventId = info.event._def.publicId;
            this.eventOrderId = info.event._def.extendedProps.event.order_fitting ? info.event._def.extendedProps.event.order_fitting.order_id : null
            this.newEventTitle = info.event._def.extendedProps.event.title;
            this.newEventText = info.event._def.extendedProps.event.text;
            this.newEventStartDate = this.moment(info.event.start).toDate();
            this.newEventEndDate = this.moment(info.event.end).toDate();
            this.newEventStartTime = this.moment(info.event.start).format("HH:mm");
            this.newEventEndTime = info.event.end ? this.moment(info.event.end).format("HH:mm") : '23:59';
            this.newEventAllDayFitting = Boolean(info.event._def.extendedProps.event.all_day);
            this.newEventColor = info.event._def.extendedProps.event.color;
            this.eventQuantity = info.event._def.extendedProps.event.order_fitting ? info.event._def.extendedProps.event.order_fitting.quantity : null;
            this.eventMeasurementType = info.event._def.extendedProps.event.order_fitting ? info.event.extendedProps.event.order_fitting.measurement_types_id : null;
            this.eventPostcode = info.event._def.extendedProps.event.order_fitting ? info.event._def.extendedProps.event.order_fitting.order.customer.fitting_postcode : null;
            this.eventStaircase = info.event._def.extendedProps.event.staircase ? "Yes" : "No";
            this.$bvModal.show("calendarModal");
          }
        },

        // Events are draggable to other dates/ times
        eventDrop: info => {
          window.axios.post(
            `event/fitting-update/${info.event._def.publicId}`,
            {
              start_at: this.moment(info.event.start).toDate(),
              end_at: this.moment(info.event.end).toDate(),
            }
          );
        }
      },
      selectedDate: this.moment().toDate()
    };
  },

  methods: {
    ...mapActions("calendars", ["fetch", "fetchMany", "save", "deleteMany"]),

    disableEventTime(info) {
      if (this.allDay === 1) {
        var startDate = this.moment(info.event.start).toDate();
        var endDate = this.moment(info.event.end).toDate();
        var time = d.setHours(0, 0, 0, 0);

        this.start_at = startDate + time;
        this.end_at = endDate + time;
      }
    },

    hideModal() {
      this.$bvModal.hide("calendarModal");
    },

    openNewEventModal() {
      this.$root.$emit("bv::show::modal", "addEventModal");
      this.eventId = '';
      this.eventOrderId = '';
      this.newEventTitle = '';
      this.newEventText = '';
      this.newEventStartDate = '';
      this.newEventEndDate = '';
      this.newEventStartTime = '';
      this.newEventEndTime = '';
      this.newEventAllDayFitting = '';
      this.newEventColor = '';
      this.eventQuantity = '';
      this.eventMeasurementType = '';
      this.eventPostcode = '';
      this.eventStaircase = '';
    },

    closeNewEventModal() {
      this.$root.$emit("bv::hide::modal", "addEventModal");
    },

    showDeleteModal() {
      this.$bvModal.show('confirmDeleteModal')
    },

    async confirmDelete() {
      await this.deleteMany({ids: [this.eventId]});
      this.$bvModal.hide('confirmDeleteModal');
      this.$bvModal.hide('calendarModal');
      await this.fetchMany();
    },

    submit() {
      let data = {
        id: this.eventId,
        title: this.newEventTitle,
        text: this.newEventText,
        color: this.newEventColor,
        start_date: this.moment(this.newEventStartDate).format("DD/MM/YYYY"),
        end_date: this.moment(this.newEventEndDate).format("DD/MM/YYYY"),
        start_time: this.newEventStartTime,
        end_time: this.newEventEndTime,
        all_day: this.newEventAllDayFitting,
      };

      this.save(data).then(response => {
        if ([200, 201].includes(response.status)) {
          this.$bvModal.hide("addEventModal");
          this.newEventTitle = null;
          this.newEventText = null;
          this.newEventStartDate = null;
          this.newEventEndDate = null;
          this.newEventStartTime = null;
          this.newEventEndTime = null;
          this.newEventColor = null;
          this.newEventAllDayFitting = null;

        }
      });
    },
  },

  watch: {
    single() {
      this.customer = this.single.customer;
    }
  }
};
</script>

<style lang="scss">
.calendar-wrapper {
  position: relative;

  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

#toDoButton {
  z-index: 999;
}
</style>
